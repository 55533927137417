$(function () {
    $('input').iCheck({
        checkboxClass: 'icheckbox_square-blue',
        radioClass: 'iradio_square-blue',
        increaseArea: '20%'
    });
    $(".select2select").select2();
    $('#classification_id').on('change', function () {
        let options = '<option value="-1">--Select Sub Classification--</option>';
        $('#sub_classification_id').html(options);
        $.get('/sub-classifications', {classification_id: $(this).val()}, function (data) {
            for (x in data) {
                options += '<option value="' + data[x]['id'] + '">' + data[x]['text'] + '</option>'
            }
            $('#sub_classification_id').html(options);
        });
    });
    $.get('/notes', function (response) {
        response = response.notes;
        let note_options = '';
        $.each(response, function (key, value) {
            note_options += "<option value='" + value.id + "'>" + $.trim(value.label) + "</option>";
        });
        $('.note_select').append(note_options);
    });
    let images = $('#images');
    if (images.length) {
        let files = '';
        let get_images = $('#get_images');
        let type = get_images.attr('class');
        let type_id = get_images.val();
        if (get_images.length > 0) {
            $.ajaxSetup({
                async: false
            });
            $.get('/files?type=' + type + '&id=' + type_id, function (res) {
                for (i in res) {
                    res[i].name = res[i].name.split(' - ')[1];
                }
                files = res;
            });
        }
        images.filer({
            showThumbs: true,
            addMore: true,
            allowDuplicates: false,
            files: files,
            captions: {
                removeConfirmation: "The file will be deleted even if you don't save. Are you sure?",
            },
            onRemove: function (itemEl, file, id) {
                let status = true;
                if (file.id) {
                    $.ajax({
                        url: '/files/' + file.id + '?type=' + type + '&type_id=' + type_id,
                        type: 'DELETE',
                        data: {
                            "_method": 'DELETE',
                            "_token": $('meta[name="csrf-token"]').attr('content')
                        },
                        success: function (result) {
                            status = result;
                        }
                    });
                }
                return status;
            }
        });
    }
    $('#btn-top-note').on('click', function () {
        $('.top_notes').each(function () {
            $(this).find('.select2select').select2('destroy');
        });
        $(".top_notes:first").clone(true, true).insertAfter(".top_notes:last");
        $('.top_notes').each(function () {
            $(this).find('.select2select').select2();
        });
    });
    $('#btn-heart-note').on('click', function () {
        $('.heart_notes').each(function () {
            $(this).find('.select2select').select2('destroy');
        });
        $(".heart_notes:first").clone(true, true).insertAfter(".heart_notes:last");
        $('.heart_notes').each(function () {
            $(this).find('.select2select').select2();
        });
    });
    $('#btn-base-note').on('click', function () {
        $('.base_notes').each(function () {
            $(this).find('.select2select').select2('destroy');
        });
        $(".base_notes:first").clone(true, true).insertAfter(".base_notes:last");
        $('.base_notes').each(function () {
            $(this).find('.select2select').select2();
        });
    });
    $('#btn-nose').on('click', function () {
        $('.noses').each(function () {
            $(this).find('.select2select').select2('destroy');
        });
        $(".noses:first").clone(true, true).insertAfter(".noses:last");
        $('.noses').each(function () {
            $(this).find('.select2select').select2();
        });
    });
    $('#btn-fragrance-personalities').on('click', function () {
        $('.fragrances').each(function () {
            $(this).find('.select2select').select2('destroy');
        });
        $(".fragrances:first").clone(true, true).insertAfter(".fragrances:last");
        $('.fragrances').each(function () {
            $(this).find('.select2select').select2();
        });
    });
    let id = getUrlParameter('id');
    if (id != undefined) {
        $('#move' + id).addClass('selected');
        $('html, body').animate({
            scrollTop: $('#move' + id).offset().top
        }, 2000);
    }
});
let getUrlParameter = function getUrlParameter(sParam) {
    let sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;
    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
};